import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store';
import gql from 'graphql-tag';
import { apolloClient } from '../vue-apollo';
import EventBus from '../event-bus';

Vue.use(VueRouter);

export const routeNames = {
  admin: 'admin',
  bookmark: 'bookmark',
  changelog: 'changelog',
  forgot: 'forgot',
  landing: 'landpage',
  login: 'login',
  home: 'accueil',
  home2: 'accueil2',
  management: 'gestion',
  potree: 'potree',
  potreeFusion: 'potreeFusion',
  privacy: 'privacy',
  project: 'projet',
  resetpwd: 'resetpwd',
  reupload: 'reupload',
  statistics: 'statistiques',
  tasks: 'tasks',
  tools: 'materiel'
};

const routes = [
  {
    path: '/',
    name: 'landpage',
    component: () => import(/* webpackChunkName: "about" */ '../views/LandingPage.vue'),
    meta: {
      requiresAuth: false,
      requiresGuest: true,
      roles: ['user', 'admin', 'manager', 'supervisor']
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Home,
    meta: {
      requiresAuth: true,
      roles: ['user', 'admin', 'manager', 'supervisor']
    }
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import(/* webpackChunkName: "about" */ '../views/PasswordResetRequestView.vue'),
    meta: {
      requiresAuth: true,
      roles: ['user', 'admin', 'manager', 'supervisor']
    }
  },
  {
    path: `/${routeNames.home}`,
    name: routeNames.home,
    meta: {
      requiresAuth: true,
      roles: ['user', 'admin', 'manager', 'supervisor'],
      breadcrumb: {
        label: 'Accueil' // Here you should use exact string as for name property in "parent" route
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestViewPaginationMultiSearch.vue')
  },
  {
    path: `/${routeNames.home2}`,
    name: routeNames.home2,
    meta: {
      requiresAuth: true,
      roles: ['user', 'admin', 'manager', 'supervisor'],
      breadcrumb: {
        label: 'Accueil' // Here you should use exact string as for name property in "parent" route
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestViewPagination.vue')
  },
  {
    path: `/${routeNames.bookmark}`,
    name: routeNames.bookmark,
    props: true,
    meta: {
      requiresAuth: true,
      roles: ['user', 'admin', 'manager', 'supervisor'],
      breadcrumb: {
        label: 'Suivi'
      }
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/BookmarkView.vue')
  },
  {
    path: `/${routeNames.changelog}/:log_id`,
    name: routeNames.changelog,
    meta: {
      requiresAuth: true,
      roles: ['user', 'admin', 'manager', 'supervisor'],
      breadcrumb: {
        label: 'ChangeLog'
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/MainComponentChangeLog.vue')
  },
  // {
  //   path: "/pagination",
  //   name: "pagination",
  //   meta: {
  //     requiresAuth: true,
  //     roles: ["user","admin","manager","supervisor"],
  //     breadcrumb: {
  //       label: 'pagination'// Here you should use exact string as for name property in "parent" route
  //     }
  //   },
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/TestViewPagination.vue"),
  // },
  {
    path: '/gestion',
    name: 'gestion',
    meta: {
      requiresAuth: true,
      roles: ['admin', 'manager', 'supervisor'],
      breadcrumb: {
        label: routeNames.home // Here you should use exact string as for name property in "parent" route
      }
    },

    beforeEnter: (to, _from, next) => {
      const roleUser = store.getters['Auth/roleUser'];
      if (roleUser) {
        if (!to.matched.some((rec) => rec.meta.roles.includes(roleUser))) {
          next({
            path: `/${routeNames.home}`,
            query: {
              redirect: to.fullPath
            }
          });
        } else {
          next();
        }
      } else {
        apolloClient
          .query({
            query: gql`
              query AUTH_USER {
                me {
                  id
                  username
                  firstName
                  lastName
                  role {
                    name
                  }
                }
              }
            `
          })
          .then((data) => {
            // Result
            // console.log(data)
            // console.log(to.meta.roles.includes(data.data.me.role.name))
            if (!to.matched.some((rec) => rec.meta.roles.includes(data.data.me.role.name))) {
              next({
                path: `/${routeNames.home}`,
                query: {
                  redirect: to.fullPath
                }
              });
            } else {
              next();
            }
          });
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UsersView.vue')
  },
  {
    path: '/projet/:id',
    name: 'projet',
    props: true,
    meta: {
      requiresAuth: true,
      roles: ['user', 'admin', 'manager', 'supervisor'],
      breadcrumb: {
        label: 'Projet',
        parent: routeNames.home // Here you should use exact string as for name property in "parent" route
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectView.vue')
  },
  {
    path: '/materiel',
    name: 'materiel',
    meta: {
      requiresAuth: true,
      roles: ['admin'],
      breadcrumb: {
        label: routeNames.home // Here you should use exact string as for name property in "parent" route
      }
    },

    beforeEnter: (to, _from, next) => {
      const roleUser = store.getters['Auth/roleUser'];
      if (roleUser) {
        if (!to.matched.some((rec) => rec.meta.roles.includes(roleUser))) {
          next({
            path: `/${routeNames.home}`,
            query: {
              redirect: to.fullPath
            }
          });
        } else {
          next();
        }
      } else {
        apolloClient
          .query({
            query: gql`
              query AUTH_USER {
                me {
                  id
                  username
                  firstName
                  lastName
                  role {
                    name
                  }
                }
              }
            `
          })
          .then((data) => {
            // Result
            if (!to.matched.some((rec) => rec.meta.roles.includes(data.data.me.role.name))) {
              next({
                path: `/${routeNames.home}`,
                query: {
                  redirect: to.fullPath
                }
              });
            } else {
              next();
            }
          });
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DevicesView.vue')
  },
  {
    path: '/reset/:token',
    name: 'resetpwd',
    props: true,
    meta: {
      requiresAuth: false,
      requiresGuest: true,
      roles: ['user', 'admin', 'manager', 'supervisor'],
      breadcrumb: {
        label: 'reset',
        parent: 'login' // Here you should use exact string as for name property in "parent" route
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PasswordResetView.vue')
  },

  {
    path: '/potree/:id2/:flag?',
    name: 'potree',
    props: true,
    meta: {
      requiresAuth: true,
      roles: ['user', 'admin', 'manager', 'supervisor'],
      breadcrumb: {
        label: 'Visualisation 3D',
        parent: 'projet' // Here you should use exact string as for name property in "parent" route
      }
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PotreeView.vue')
  },
  {
    path: '/potreeFusion/:id3',
    name: 'potreeFusion',
    props: true,
    meta: {
      requiresAuth: true,
      roles: ['user', 'admin', 'manager', 'supervisor'],
      breadcrumb: {
        label: 'Fusion',
        parent: 'projet' // Here you should use exact string as for name property in "parent" route
      }
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PotreeFusionView.vue')
  },
  {
    path: '/statistiques',
    name: 'statistiques',
    props: true,
    meta: {
      requiresAuth: true,
      roles: ['user', 'admin', 'manager', 'supervisor'],
      breadcrumb: {
        label: 'Statistiques',
        parent: routeNames.home // Here you should use exact string as for name property in "parent" route
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Statistics.vue')
  },
  {
    path: '/mentions-legales',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue'),
    meta: {
      requiresAuth: false,
      requiresGuest: true,
      roles: ['user', 'admin', 'manager', 'supervisor']
    }
  },
  {
    path: '/reupload',
    name: 'reupload',
    component: () => import(/* webpackChunkName: "about" */ '../views/Reupload.vue'),
    meta: {
      requiresAuth: true,
      requiresGuest: false,
      roles: ['admin']
    }
  },
  {
    path: "/projects",
    name: "admin",
    alias: '/dev/admin',
    component: () => import(/* webpackChunkName: "about" */ "../views/AdminView.vue"),
    meta: {
      requiresAuth: true,
      requiresGuest : false,
      roles: ["admin"]

    }
  }
];

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'is-active',
  routes
});

router.beforeEach((to, from, next) => {
  if (window.Potree && (from.name == 'projet')) {
    window.Potree.WebGLAnimationsRequestId = false;
  }
  const isLoggedIn = store.getters['Auth/isAuth'];
  // const roleUser = store.getters["Auth/roleUser"];

  if (to.matched.some((rec) => rec.meta.requiresAuth)) {
    if (to.name !== 'login' && !isLoggedIn) {
      // store.dispatch('Auth/logoutUser').catch(() => {});
      // next({
      //   path: `/${routeNames.home}`,
      //   query: {
      //     redirect: to.fullPath
      //   }
      // });
      next();
    } else if (to.name === 'login' && isLoggedIn) {
      store.dispatch('Auth/getAuthUser').catch(() => {});
      next({
        path: `/${routeNames.home}`,
        query: {
          redirect: to.fullPath
        }
      });
    } else if ([routeNames.home, routeNames.bookmark].indexOf(from.name) >= 0) {
      Vue.prototype.$raphalUtils.previousProjectsFilter = '';
      next();
    }
    //  else if (! to.matched.some((rec) =>  rec.meta.roles.includes(roleUser))) {
    //   next({
    //     path: `/${routeNames.home}`,
    //     query: {
    //       redirect: to.fullPath,
    //     },
    //   });
    // }
    else {
      next();
    }
  } else if (to.matched.some((rec) => rec.meta.requiresGuest)) {
    if (isLoggedIn) {
      next({
        path: `/${routeNames.home}`,
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

// eslint-disable-next-line
router.afterEach((to, from) => {
  const isLoggedIn = store.getters['Auth/isAuth'];
  if (isLoggedIn && (to.name === routeNames.home) && (to.params.filter !== Vue.prototype.$raphalUtils.previousProjectsFilter)) {
    EventBus.$emit("callProjectsFilter", to.params, from);
  }
})

export default router;
